/* Header
------------------------------------------------------------*/

.c {
    &-header__top {
        display: flex;
        justify-content: space-between;
    }
    &-header__text {
        padding-top: 4px;
        font-size: 1.2rem;
        color: #9D9E9E;
        padding-left: 10px;
    }
    &-header__list {
        margin-top: 2px;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        padding-left: 24px;
        padding-right: 27px;
        @media only screen and (max-width: 1200px) {
            padding: 0 15px;
        }
        @media only screen and (max-width: 1024px) {
            align-items: center;
        }
    }
    &-header__logo {
        width: 278.227px;
        margin-top: 15px;
        margin-right: 30px;
        @media only screen and (max-width: 1200px) {
            min-width: 200px;
        }
    }
    &-header__right {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        max-width: 893.119px;
        width: 90%;
        -webkit-box-align: center;
        @media only screen and (max-width: 1024px) {
            display: none;
        }
    }
    &-header__menu {
        margin-top: 30px;
        width: 90%;
        font-size: 1.5rem;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        max-width: 649.368px;
    }
    &-header__contact {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-left: 20px;
        @media only screen and (max-width: 1024px) {
            align-items: flex-start;
            margin-left: 0;
            margin-top: 40px;
        }
    }
    &-header__phone {
        width: 192.955px;
        @media only screen and (max-width: 1024px) {
            margin-bottom: 5px;
        }
    }
    &-header__mail {
        font-size: 1.3rem;
        margin-top: 5px;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        width: 102.918px;
        height: 23.685px;
        background-color: #1A5292;
        color: white;
        border-radius: 3px;
    }
}


/* Main menu
------------------------------------------------------------*/

.c-header__meta {
    z-index: 1001;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    display: -webkit-box;
    display: flex;
    position: absolute;
    top: 12px;
    right: 2.6%;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.p-burger {
    display: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    z-index: 1001;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    @media only screen and (max-width: 1024px) {
        display: -webkit-box;
        display: flex;
    }
}

.p-burger .p-burger__container {
    justify-content: space-around;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    height: 21px;
    position: relative;
    cursor: pointer;
    margin-bottom: 3px;
}

.p-burger .p-burger__container {
    width: 32.3px;
    display: -webkit-box;
    display: flex;
}

.p-burger .c-line {
    width: 32.3px;
    height: 2px;
    background-color: #1A5292;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.p-burger__text {
    font-weight: bolder;
    color: #231916;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.p-burger.is-active {
    top: 30px;
    position: absolute;
    right: 20px;
}

.p-burger.is-active .c-line {
    position: absolute;
}

.p-burger.is-active .c-header__text {
    display: none;
}

.p-burger.is-active .c-line:nth-child(1) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.p-burger.is-active .c-line:nth-child(2) {
    display: none;
}

.p-burger.is-active .c-line:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.p-burger__text.is-active {
    color: black;
}

.c-menu {
    padding: 5px 20px;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: white;
    top: 0;
    left: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: 0.4s -webkit-transform ease-in-out;
    transition: 0.4s -webkit-transform ease-in-out;
    transition: 0.4s transform ease-in-out;
    transition: 0.4s transform ease-in-out, 0.4s -webkit-transform ease-in-out;
}

.c-menu.is-open {
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.c-header .l-container {
    height: 578px;
    padding-top: 32px;
}

.c-menu__logo {
    z-index: 5;
    position: absolute;
    top: 32px;
    left: 20px;
}

.c-menu ul {
    position: absolute;
    width: 70%;
    top: 17%;
    left: 43%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.c-menu li {
    margin-bottom: 20px;
    font-size: 1.8rem;
    color: #1A5292;
}

body.is-hidden {
    touch-action: none;
    -ms-touch-action: none;
    overflow-y: hidden;
}

.c-header__meta {
    display: none;
}

@media only screen and (max-width: 767px) {
    .c-header__meta {
        display: block;
    }
}