@charset "UTF-8";
/*------------------------------------------------------------
	Reset
------------------------------------------------------------*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, dialog, figure, footer, header,
nav, section,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block;
}

html {
  font-size: 62.5%;
}

body {
  -webkit-text-size-adjust: 100%;
}

body, table,
input, textarea, select, option,
h1, h2, h3, h4, h5, h6 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

table,
input, textarea, select, option {
  line-height: 1.1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

:focus {
  outline: 0;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

img {
  vertical-align: top;
}

a,
a img {
  transition: 0.3s ease-in-out;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover,
a:hover img {
  opacity: 0.80;
  filter: alpha(opacity=80);
  -ms-filter: "alpha(opacity=80)";
}

img {
  max-width: 100%;
}

.sp-only {
  display: none;
}

@media only screen and (max-width: 767px) {
  .pc-only {
    display: none;
  }
  .sp-only {
    display: block;
  }
}

/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Layout .l-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
/* Header
------------------------------------------------------------*/
.c-header__top {
  display: flex;
  justify-content: space-between;
}

.c-header__text {
  padding-top: 4px;
  font-size: 1.2rem;
  color: #9D9E9E;
  padding-left: 10px;
}

.c-header__list {
  margin-top: 2px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 27px;
}

@media only screen and (max-width: 1200px) {
  .c-header__list {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 1024px) {
  .c-header__list {
    align-items: center;
  }
}

.c-header__logo {
  width: 278.227px;
  margin-top: 15px;
  margin-right: 30px;
}

@media only screen and (max-width: 1200px) {
  .c-header__logo {
    min-width: 200px;
  }
}

.c-header__right {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 893.119px;
  width: 90%;
  -webkit-box-align: center;
}

@media only screen and (max-width: 1024px) {
  .c-header__right {
    display: none;
  }
}

.c-header__menu {
  margin-top: 30px;
  width: 90%;
  font-size: 1.5rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 649.368px;
}

.c-header__contact {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 20px;
}

@media only screen and (max-width: 1024px) {
  .c-header__contact {
    align-items: flex-start;
    margin-left: 0;
    margin-top: 40px;
  }
}

.c-header__phone {
  width: 192.955px;
}

@media only screen and (max-width: 1024px) {
  .c-header__phone {
    margin-bottom: 5px;
  }
}

.c-header__mail {
  font-size: 1.3rem;
  margin-top: 5px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 102.918px;
  height: 23.685px;
  background-color: #1A5292;
  color: white;
  border-radius: 3px;
}

/* Main menu
------------------------------------------------------------*/
.c-header__meta {
  z-index: 1001;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  display: -webkit-box;
  display: flex;
  position: absolute;
  top: 12px;
  right: 2.6%;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.p-burger {
  display: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 1001;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .p-burger {
    display: -webkit-box;
    display: flex;
  }
}

.p-burger .p-burger__container {
  justify-content: space-around;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  height: 21px;
  position: relative;
  cursor: pointer;
  margin-bottom: 3px;
}

.p-burger .p-burger__container {
  width: 32.3px;
  display: -webkit-box;
  display: flex;
}

.p-burger .c-line {
  width: 32.3px;
  height: 2px;
  background-color: #1A5292;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.p-burger__text {
  font-weight: bolder;
  color: #231916;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.p-burger.is-active {
  top: 30px;
  position: absolute;
  right: 20px;
}

.p-burger.is-active .c-line {
  position: absolute;
}

.p-burger.is-active .c-header__text {
  display: none;
}

.p-burger.is-active .c-line:nth-child(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.p-burger.is-active .c-line:nth-child(2) {
  display: none;
}

.p-burger.is-active .c-line:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.p-burger__text.is-active {
  color: black;
}

.c-menu {
  padding: 5px 20px;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: white;
  top: 0;
  left: 0;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: 0.4s -webkit-transform ease-in-out;
  transition: 0.4s -webkit-transform ease-in-out;
  transition: 0.4s transform ease-in-out;
  transition: 0.4s transform ease-in-out, 0.4s -webkit-transform ease-in-out;
}

.c-menu.is-open {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.c-header .l-container {
  height: 578px;
  padding-top: 32px;
}

.c-menu__logo {
  z-index: 5;
  position: absolute;
  top: 32px;
  left: 20px;
}

.c-menu ul {
  position: absolute;
  width: 70%;
  top: 17%;
  left: 43%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.c-menu li {
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: #1A5292;
}

body.is-hidden {
  touch-action: none;
  -ms-touch-action: none;
  overflow-y: hidden;
}

.c-header__meta {
  display: none;
}

@media only screen and (max-width: 767px) {
  .c-header__meta {
    display: block;
  }
}

/*------------------------------------------------------------
footer
------------------------------------------------------------*/
.c-footer {
  padding-top: 78px;
  background-color: #F5F7FC;
  padding-bottom: 63.97px;
}

.c-footer__list {
  display: flex;
  justify-content: space-between;
  max-width: 1081.65px;
  margin: 0 auto;
  padding: 0 20px;
}

@media only screen and (max-width: 1024px) {
  .c-footer__list {
    flex-wrap: wrap;
    max-width: 850px;
  }
}

@media only screen and (max-width: 767px) {
  .c-footer__left {
    width: 100%;
  }
}

.c-footer__logo {
  width: 278.227px;
}

.c-footer__address {
  font-size: 1.5rem;
  margin-top: 26px;
}

.c-footer__address p {
  letter-spacing: 1px;
  margin-bottom: 21px;
}

.c-footer__map {
  width: 100%;
  max-width: 376.8px;
  height: 250.56px;
}

@media only screen and (max-width: 1024px) {
  .c-footer__map {
    margin: 0 35px;
  }
}

@media only screen and (max-width: 767px) {
  .c-footer__map {
    margin: 0;
  }
}

.c-footer__right {
  margin-top: 25px;
  max-width: 277.65px;
}

@media only screen and (max-width: 1024px) {
  .c-footer__right {
    margin-bottom: 40px;
  }
}

.c-footer__right h3 {
  font-size: 2.4rem;
}

@media only screen and (max-width: 516px) {
  .c-footer__right h3 {
    font-size: 2rem;
  }
}

.c-footer__right p {
  line-height: 1.4;
  font-size: 1.2rem;
}

.c-footer__right a {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 1.3rem;
  color: white;
  background-color: #005BAC;
  border-radius: 3px;
  width: 102.91px;
  height: 23.68px;
  margin-top: 17px;
}

.c-footer__black {
  font-size: 1.2rem;
  background-color: #3F3B3A;
  height: 139.7px;
  margin-top: 14px;
}

.c-footer__button {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 1213.726px;
  margin: 0 auto;
  color: white;
  align-items: center;
  height: 100%;
  padding: 0 20px;
}

@media only screen and (max-width: 767px) {
  .c-footer__button {
    flex-wrap: wrap;
    height: auto;
  }
}

.c-footer__link {
  display: flex;
  justify-content: space-between;
  max-width: 233.643px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .c-footer__link {
    margin: 43px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .c-footer__copy {
    width: 100%;
  }
}

.c-footer__bottom {
  background-color: #005BAC;
  display: flex;
  position: relative;
  height: 63.97px;
  border-top: 1px solid white;
  z-index: 999;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: 0.4s -webkit-transform ease-in-out;
  transition: 0.4s -webkit-transform ease-in-out;
  transition: 0.4s transform ease-in-out;
  transition: 0.4s transform ease-in-out, 0.4s -webkit-transform ease-in-out;
}

.c-footer__bottom::after {
  position: absolute;
  content: "";
  background-color: white;
  width: 1px;
  height: 53.197px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.c-footer__item {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .c-footer__item {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 516px) {
  .c-footer__item {
    padding: 0 15px;
  }
}

.c-footer__phone {
  max-width: 226.82px;
  width: 100%;
  display: flex;
}

.c-footer__mail {
  max-width: 166.951px;
  width: 100%;
}

.c-footer__bottom.is-show {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

/*------------------------------------------------------------
sidebar
------------------------------------------------------------*/
/* Breadcrumb
------------------------------------------------------------*/
/* layout
------------------------------------------------------------*/
body {
  font-family: Noto Sans JP, sans-serif;
  font-size: 1.4rem;
  line-height: 1.5;
}

/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Component .c-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
/*------------------------------------------------------------
btn
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
title
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
icon
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
form
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
text
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
navi
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
img
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
list
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
table
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
line
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
video
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
video
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
other
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Project .p-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/
.p-top1__gray {
  padding: 101px 20px 56px;
  background-color: #F6F6F6;
}

@media only screen and (max-width: 767px) {
  .p-top1__gray {
    padding: 80px 20px 56px;
  }
}

@media only screen and (max-width: 516px) {
  .p-top1__gray {
    padding: 70px 20px 56px;
  }
}

.p-top1__ueki {
  max-width: 729.063px;
  margin: 0 auto;
}

@media only screen and (max-width: 1024px) {
  .p-top1__ueki {
    max-width: 621.063px;
  }
}

.p-top1__desc {
  margin-top: 24px;
  font-size: 2rem;
  text-align: center;
  line-height: 2.1;
}

@media only screen and (max-width: 1024px) {
  .p-top1__desc {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 767px) {
  .p-top1__desc {
    line-height: 1.9;
    font-size: 1.6rem;
  }
}

.p-top1__white {
  max-width: 1140.16px;
  margin: 97px auto 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

@media only screen and (max-width: 767px) {
  .p-top1__white {
    margin: 80px auto;
  }
}

.p-top1__title {
  font-size: 2rem;
  position: relative;
  display: inline-block;
  margin: 0 auto;
}

@media only screen and (max-width: 516px) {
  .p-top1__title {
    font-size: 1.8rem;
  }
}

.p-top1__title::after {
  content: "";
  background-color: #005BAC;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -3px;
  height: 1.745px;
  width: 74.906px;
}

.p-top1__list {
  margin-top: 59px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .p-top1__list {
    flex-wrap: wrap;
  }
}

.p-top1__item {
  width: 21.5%;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1024px) {
  .p-top1__item {
    width: 22.5%;
  }
}

@media only screen and (max-width: 767px) {
  .p-top1__item {
    width: 46%;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 516px) {
  .p-top1__item {
    width: 48%;
    margin-bottom: 30px;
  }
}

.p-top1__bottom {
  margin-top: -16px;
  background-color: #F5F7FC;
  padding: 13px 21px;
  flex: 1;
}

@media only screen and (max-width: 516px) {
  .p-top1__bottom {
    padding: 13px 14px;
  }
}

.p-top1__info {
  font-size: 1.7rem;
  text-align: center;
  color: #185497;
}

@media only screen and (max-width: 516px) {
  .p-top1__info {
    font-size: 1.5rem;
  }
}

.p-top1__content {
  line-height: 1.7;
  margin-top: 5px;
}

.p-top1__big {
  margin: 43px 0;
}

@media only screen and (max-width: 767px) {
  .p-top1__pc {
    display: none;
  }
}

.p-top1__sp {
  display: none;
}

@media only screen and (max-width: 767px) {
  .p-top1__sp {
    display: block;
  }
}

.p-top2 {
  background-image: url(../img/index/top2_bg.jpg);
  background-size: cover;
  padding-bottom: 12px;
}

.p-top2__button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 74px;
}

.p-top2__title {
  font-size: 2.4rem;
  text-align: center;
  background-color: #3B7AAF;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 66px;
  color: white;
  letter-spacing: 4px;
}

@media only screen and (max-width: 516px) {
  .p-top2__title {
    font-size: 2rem;
  }
}

.p-top2__list .slick-slide {
  margin: 0 13px;
}

.p-top2__info {
  margin-top: 9px;
  text-align: center;
  letter-spacing: 2px;
}

@media only screen and (max-width: 516px) {
  .p-top2__info {
    letter-spacing: 0;
  }
}

.p-top2__link {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 193.745px;
  height: 48px;
  border: 1px solid;
  margin: 54px auto;
  letter-spacing: 3px;
}

.p-top3 {
  padding: 0 20px;
  max-width: 1079.432px;
  margin: 137px auto 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .p-top3 {
    margin: 80px auto 0;
  }
}

.p-top3__title {
  font-size: 1.6rem;
}

@media only screen and (max-width: 650px) {
  .p-top3__title {
    width: 100%;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 516px) {
  .p-top3__title {
    font-size: 1.4rem;
  }
}

.p-top3__list {
  width: 100%;
  margin: 67px 0 10px;
}

.p-top3__item {
  padding: 10px 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-bottom: 1px dashed #9F9F9F;
}

.p-top3__item:first-child {
  border-top: 1px dashed #9F9F9F;
}

.p-top3__image {
  width: 81.927px;
  margin-right: 67px;
}

@media only screen and (max-width: 1024px) {
  .p-top3__image {
    margin-right: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .p-top3__image {
    margin-right: 20px;
  }
}

.p-top3__right {
  display: flex;
}

@media only screen and (max-width: 650px) {
  .p-top3__right {
    flex-wrap: wrap;
  }
}

.p-top3__category {
  font-size: 1.213rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  min-width: 98.78px;
  height: 21.35px;
  background-color: #9FA0A0;
  padding: 0 20px;
  margin-left: 21px;
  margin-right: 19px;
  color: white;
}

.p-top2__meta {
  padding-top: 74px;
  position: relative;
}

.p-top2__container {
  width: 88%;
  height: 100%;
  position: relative;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 2;
}

@media only screen and (max-width: 767px) {
  .p-top2__container {
    width: 82%;
  }
}

.p-top2__left,
.p-top2__right {
  width: 20.44px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .p-top2__left,
  .p-top2__right {
    width: 15px;
  }
}

.p-top2__left {
  left: -31.18px;
}

.p-top2__right {
  right: -31.18px;
}

.p-top4 {
  padding: 0 20px;
  max-width: 1147.739px;
  margin: 103px auto 139px;
  width: 90%;
}

.p-top4 .slick-slide {
  margin: 0 6px;
}

@media only screen and (max-width: 516px) {
  .p-top4 {
    margin: 80px auto 130px;
  }
}

.p-top4__list .slick-prev,
.p-top4__list .slick-next {
  width: 15.024px;
  height: 27.435px;
}

.p-top4__list .slick-next {
  right: -47px;
}

@media only screen and (max-width: 1024px) {
  .p-top4__list .slick-next {
    right: -25px;
  }
}

@media only screen and (max-width: 516px) {
  .p-top4__list .slick-next {
    right: -20px;
  }
}

.p-top4__list .slick-prev {
  left: -47px;
}

@media only screen and (max-width: 1024px) {
  .p-top4__list .slick-prev {
    left: -25px;
  }
}

@media only screen and (max-width: 516px) {
  .p-top4__list .slick-prev {
    left: -20px;
  }
}

.p-top4__list .slick-prev:before,
.p-top4__list .slick-next:before {
  background-size: cover;
  background-image: url(../img/index/arrow_black_left.svg);
  width: 15.024px;
  height: 27.435px;
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

.p-top4__list .slick-next:before {
  background-image: url(../img/index/arrow_black_right.svg);
}

.c-slide {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 19px;
  padding-left: 56px;
}

@media only screen and (max-width: 1024px) {
  .c-slide {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .c-slide {
    padding-left: 20px;
  }
}

.c-slide__black {
  margin-right: -34px;
  width: 8.82%;
  margin-top: 23px;
}

@media only screen and (max-width: 767px) {
  .c-slide__black {
    margin-right: 0;
    width: 8.82%;
    margin-top: 5px;
  }
}

.c-slide__right {
  position: relative;
  margin-bottom: -3.5%;
  width: 76.5%;
}

@media only screen and (max-width: 516px) {
  .c-slide__right {
    width: 84.5%;
  }
}

.c-slide__white {
  width: 2.26%;
  position: absolute;
  right: 3.2%;
  top: 42%;
}

@-webkit-keyframes moveScrollBtn {
  0% {
    top: 0;
  }
  100% {
    top: calc(100% - 7px);
  }
}

@keyframes moveScrollBtn {
  0% {
    top: 0;
  }
  100% {
    top: calc(100% - 7px);
  }
}

.p-top-mv__scroll {
  margin-bottom: auto;
  font-size: 0.9rem;
  letter-spacing: 1px;
  color: #3e63a2;
  font-weight: bold;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-family: "Lato", sans-serif;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  line-height: 1.2;
}

@media only screen and (max-width: 516px) {
  .p-top-mv__scroll {
    display: none;
  }
}

@media only screen and (max-width: 1225px) {
  .p-top-mv__scroll {
    left: -63%;
  }
}

@media only screen and (max-width: 1024px) {
  .p-top-mv__scroll {
    left: -9%;
  }
}

.p-top-mv__scroll span {
  position: relative;
  display: block;
  height: 125px;
  width: 1px;
  background-color: #3e63a2;
  margin-top: 5px;
}

.p-top-mv__scroll span::before {
  position: absolute;
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #3e63a2;
  left: -3px;
  top: 0;
  -webkit-animation: moveScrollBtn .9s ease-in-out infinite;
  animation: moveScrollBtn .9s ease-in-out infinite;
}

/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/
.p-company__main {
  max-width: 1138.93px;
  margin: 134px auto 263px;
  padding: 0 20px;
}

@media only screen and (max-width: 767px) {
  .p-company__main {
    margin: 100px auto 170px;
  }
}

@media only screen and (max-width: 516px) {
  .p-company__main {
    margin: 80px auto 140px;
  }
}

.p-company__part {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 128px;
}

@media only screen and (max-width: 767px) {
  .p-company__part {
    margin-bottom: 90px;
  }
}

.p-company__list {
  display: flex;
  margin-top: 72px;
}

@media only screen and (max-width: 767px) {
  .p-company__list {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin-top: 50px;
  }
}

.p-company__left {
  font-size: 1.6rem;
  width: 58%;
  margin-right: 38px;
  letter-spacing: 1.7px;
  line-height: 1.98;
}

@media only screen and (max-width: 1024px) {
  .p-company__left {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .p-company__left {
    width: 100%;
  }
}

.p-company__sign {
  text-align: right;
  margin-top: 16px;
  margin-right: 8px;
}

.p-company__right {
  margin-top: -10px;
  width: 38.5%;
}

@media only screen and (max-width: 1024px) {
  .p-company__right {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .p-company__right {
    width: 100%;
    max-width: 423px;
    margin-bottom: 30px;
  }
}

.p-company__table {
  width: 100%;
  margin-top: 83px;
}

@media only screen and (max-width: 767px) {
  .p-company__table {
    margin-top: 60px;
  }
}

.p-company__item {
  display: flex;
  border-bottom: 1px solid #C9CACA;
}

.p-company__item:first-child {
  border-top: 1px solid #C9CACA;
}

@media only screen and (max-width: 516px) {
  .p-company__item {
    flex-wrap: wrap;
  }
}

.p-company__info {
  width: 22.5%;
  position: relative;
  margin: 7px 0px;
  padding-top: 22px;
  border-right: 1px solid #C9CACA;
}

@media only screen and (max-width: 516px) {
  .p-company__info {
    width: 100%;
    border-right: none;
  }
}

.p-company__text {
  width: 70%;
  padding: 29px 43px;
  line-height: 1.8;
  letter-spacing: 1px;
}

@media only screen and (max-width: 767px) {
  .p-company__text {
    padding: 29px 0 29px 29px;
  }
}

@media only screen and (max-width: 516px) {
  .p-company__text {
    width: 100%;
    border-right: none;
    padding: 20px 0 29px;
  }
}

.p-company__text p {
  margin-bottom: 26px;
}

.c-subpage {
  margin-top: 19px;
}

.c-subpage__title {
  height: 93.45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F6F6F6;
}

.c-subpage__title h2 {
  font-size: 2rem;
}

.p-service__container {
  margin: 90px auto 89px;
  max-width: 1038.44px;
  padding: 0 20px;
}

.p-service__top {
  display: flex;
  flex-wrap: wrap;
  margin-left: calc( var(--px1) * -1);
}

.p-service__link {
  width: calc(100% / 3 - var(--px1));
  margin-left: var(--px1);
  margin-bottom: 32px;
  font-size: 2rem;
  color: #005BAC;
  border: 1px solid;
  height: 92.5px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
}

@media only screen and (max-width: 1024px) {
  .p-service__link {
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 516px) {
  .p-service__link {
    width: calc(100% / 2 - var(--px1));
  }
}

@media only screen and (max-width: 375px) {
  .p-service__link {
    font-size: 1.4rem;
  }
}

.p-service__link img {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  width: 16.43px;
}

@media only screen and (max-width: 1024px) {
  .p-service__link img {
    right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .p-service__link img {
    display: none;
  }
}

.p-service__part {
  padding-top: 122px;
}

.p-service__part:nth-child(2) {
  padding-top: 0;
}

.p-service__title {
  font-size: 2.4rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  color: white;
  background-color: #3B7AAF;
  position: relative;
  height: 66px;
  letter-spacing: 5px;
}

@media only screen and (max-width: 516px) {
  .p-service__title {
    font-size: 2rem;
  }
}

.p-service__title img {
  position: absolute;
  right: 33px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 46.65px;
}

@media only screen and (max-width: 516px) {
  .p-service__title img {
    right: 10px;
    width: 37.65px;
  }
}

.p-service__content {
  max-width: 1094.17px;
  margin: 79px auto 0;
  padding: 0 20px;
}

@media only screen and (max-width: 516px) {
  .p-service__content {
    margin: 70px auto 0;
  }
}

.p-service__info {
  font-size: 2rem;
  letter-spacing: 2px;
}

.p-service__list {
  display: flex;
  margin-top: 46px;
  letter-spacing: 1.5px;
  line-height: 2;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .p-service__list {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 516px) {
  .p-service__list {
    margin-top: 30px;
  }
}

.p-service__left {
  font-size: 1.6rem;
  width: 45%;
}

@media only screen and (max-width: 767px) {
  .p-service__left {
    width: 100%;
  }
}

.p-service__right {
  width: 51.5%;
  margin-top: 8px;
}

@media only screen and (max-width: 767px) {
  .p-service__right {
    margin-top: 30px;
    width: 100%;
  }
}

.p-service__image {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 57px;
}

@media only screen and (max-width: 767px) {
  .p-service__image {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 516px) {
  .p-service__image {
    margin-top: 20px;
  }
}

.p-service__item {
  width: 31%;
}

.p-service__family {
  color: #005BAC;
  font-size: 1.9rem;
  background-color: #F6F6F6;
  width: 269.46px;
  height: 76.88px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin: 0 auto 248px;
  border: 1px solid;
}

@media only screen and (max-width: 767px) {
  .p-service__family {
    margin: 0 auto 200px;
  }
}

@media only screen and (max-width: 516px) {
  .p-service__family {
    width: 249.46px;
    font-size: 1.6rem;
    margin: 0 auto 160px;
  }
}

.p-service__family span {
  margin-left: 51px;
  margin-right: 13px;
  letter-spacing: -1px;
}

.p-service__family img {
  width: 37.7px;
}

.p-service__big {
  max-width: 1140.16px;
  margin: 56px auto 41px;
  padding: 0 20px;
}

:root {
  --px1: 61px;
}

@media only screen and (max-width: 1024px) {
  :root {
    --px1: 40px;
  }
}

@media only screen and (max-width: 767px) {
  :root {
    --px1: 20px;
  }
}

.p-recruit__content {
  letter-spacing: 2px;
  line-height: 2;
  font-size: 1.6rem;
  text-align: center;
  margin: 203px 0 343px;
}

@media only screen and (max-width: 767px) {
  .p-recruit__content {
    margin: 150px 0 280px;
  }
}

@media only screen and (max-width: 516px) {
  .p-recruit__content {
    margin: 150px 0 230px;
  }
}

.p-privacy__main {
  padding: 0 20px;
}

.p-privacy__content {
  line-height: 1.6;
  max-width: 787.28px;
  margin: 141px auto 259px;
}

@media only screen and (max-width: 767px) {
  .p-privacy__content {
    margin: 100px auto 170px;
  }
}

.p-privacy__content p {
  margin-bottom: 29px;
}

.p-privacy__content p span {
  color: #005298;
  margin: 0 3px;
}

.p-post__main {
  padding: 0 20px;
  margin-bottom: 259px;
}

.p-post__title {
  font-size: 1.8rem;
  text-align: center;
  max-width: 1082.4px;
  margin: 104px auto 30px;
  border-bottom: 1px solid;
  padding-bottom: 14px;
  letter-spacing: 4px;
}

@media only screen and (max-width: 516px) {
  .p-post__title {
    letter-spacing: 1px;
  }
}

.p-post__content {
  max-width: 1018.63px;
  margin: 0 auto;
}

.p-post__content p {
  line-height: 1.75;
  margin: 15px 0;
  font-size: 1.5rem;
}

.p-post__content img {
  height: auto;
  margin: 15px 0;
}

.p-example__main {
  padding: 0 20px;
}

.p-example__list {
  flex-wrap: wrap;
  max-width: 965.07px;
  margin: 157px auto 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .p-example__list {
    margin: 120px auto 0;
  }
}

@media only screen and (max-width: 516px) {
  .p-example__list {
    margin: 100px auto 0;
  }
}

.p-example__item {
  margin-bottom: 68px;
  width: 47%;
}

@media only screen and (max-width: 516px) {
  .p-example__item {
    margin-bottom: 40px;
  }
}

.p-example__title {
  text-align: center;
  font-size: 1.8rem;
  margin-top: 13px;
  letter-spacing: 4px;
}

@media only screen and (max-width: 767px) {
  .p-example__title {
    font-size: 1.6rem;
    letter-spacing: 1px;
  }
}

.p-family__main {
  margin-bottom: 252px;
}

@media only screen and (max-width: 767px) {
  .p-family__main {
    margin-bottom: 200px;
  }
}

@media only screen and (max-width: 516px) {
  .p-family__main {
    margin-bottom: 160px;
  }
}

.p-family__big {
  margin-top: 21px;
}

.p-family__bg {
  background-image: url(../img/family/bg.jpg);
  background-size: cover;
  border-bottom: 2px solid;
  padding: 87px 7px 0;
}

@media only screen and (max-width: 1024px) {
  .p-family__bg {
    padding: 87px 20px 0;
  }
}

@media only screen and (max-width: 516px) {
  .p-family__bg {
    padding: 60px 20px 0;
  }
}

@media only screen and (max-width: 767px) {
  .p-family__bg.pc {
    display: none;
  }
}

.p-family__bg.sp {
  display: none;
}

@media only screen and (max-width: 767px) {
  .p-family__bg.sp {
    display: block;
  }
}

.p-family__top {
  max-width: 1020.52px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.p-family__man {
  width: 23.5%;
}

.p-family__content {
  margin-top: -14px;
  width: 47%;
}

@media only screen and (max-width: 767px) {
  .p-family__content {
    max-width: 475px;
    width: 100%;
    margin: 0 auto;
  }
}

.p-family__content h3 {
  font-size: 3.955rem;
  border-bottom: 4px dotted;
}

@media only screen and (max-width: 1024px) {
  .p-family__content h3 {
    font-size: 2.8rem;
  }
}

@media only screen and (max-width: 767px) {
  .p-family__content h3 {
    font-size: 3.955rem;
  }
}

@media only screen and (max-width: 516px) {
  .p-family__content h3 {
    font-size: 2.3rem;
  }
}

.p-family__content p {
  line-height: 1.55;
  font-size: 2.5rem;
  letter-spacing: 1px;
  margin-top: 18px;
}

@media only screen and (max-width: 1024px) {
  .p-family__content p {
    font-size: 2.2rem;
  }
}

@media only screen and (max-width: 767px) {
  .p-family__content p {
    font-size: 2.5rem;
  }
}

@media only screen and (max-width: 516px) {
  .p-family__content p {
    font-size: 1.8rem;
  }
}

.p-family__content p span {
  font-size: 2.9rem;
}

@media only screen and (max-width: 1024px) {
  .p-family__content p span {
    font-size: 2.6rem;
  }
}

@media only screen and (max-width: 767px) {
  .p-family__content p span {
    font-size: 2.9rem;
  }
}

@media only screen and (max-width: 516px) {
  .p-family__content p span {
    font-size: 2rem;
  }
}

.p-family__people {
  width: 25.5%;
}

@media only screen and (max-width: 767px) {
  .p-family__people {
    width: 100%;
    margin: 55px auto 70px;
    max-width: 559.22px;
  }
}

@media only screen and (max-width: 516px) {
  .p-family__people {
    margin: 30px auto 50px;
  }
}

.p-family__title {
  max-width: 1073.75px;
  margin: 20px auto;
  z-index: 2;
  position: relative;
}

.p-family__text {
  font-size: 2.1rem;
  max-width: 815.65px;
  margin: 0 auto;
  line-height: 1.3;
}

@media only screen and (max-width: 767px) {
  .p-family__text {
    position: relative;
    margin: 50px auto;
  }
}

@media only screen and (max-width: 516px) {
  .p-family__text {
    font-size: 1.8rem;
    margin: 50px auto 30px;
  }
}

.p-family__text span {
  z-index: 2;
  position: relative;
  display: block;
  max-width: 545px;
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
  .p-family__cloud {
    max-width: 510px;
    position: absolute;
    top: -66%;
    right: 40px;
  }
}

@media only screen and (max-width: 516px) {
  .p-family__cloud {
    right: 16px;
  }
}

.p-family__house {
  max-width: 1300px;
  margin: 25px auto -2px;
}

@media only screen and (max-width: 767px) {
  .p-family__house {
    max-width: 621.82px;
    z-index: 2;
    position: relative;
  }
}

.p-family__white {
  margin-top: 53px;
  font-size: 1.8rem;
  padding: 0 20px;
}

@media only screen and (max-width: 516px) {
  .p-family__white {
    font-size: 1.6rem;
  }
}

.p-family__detail {
  font-size: 3.955rem;
  text-align: center;
}

@media only screen and (max-width: 1024px) {
  .p-family__detail {
    font-size: 2.8rem;
  }
}

@media only screen and (max-width: 516px) {
  .p-family__detail {
    font-size: 2rem;
  }
}

.p-family__desc {
  max-width: 861.65px;
  margin: 27px auto 23px;
  letter-spacing: 1px;
}

.p-family__letter {
  letter-spacing: 0.92px;
  margin: 30px 0 34px;
  line-height: 1.55;
}

.p-family__list {
  max-width: 951.18px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.p-family__item {
  color: white;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 3.127rem;
  background-color: #005BAC;
  height: 82px;
  width: 30%;
  border-radius: 11px;
}

@media only screen and (max-width: 1024px) {
  .p-family__item {
    font-size: 2.8rem;
  }
}

@media only screen and (max-width: 767px) {
  .p-family__item {
    font-size: 2.4rem;
  }
}

@media only screen and (max-width: 516px) {
  .p-family__item {
    font-size: 1.6rem;
    height: 71px;
  }
}

.p-family__bottom {
  margin-top: 137px;
}

@media only screen and (max-width: 767px) {
  .p-family__bottom {
    margin-top: 110px;
  }
}

@media only screen and (max-width: 516px) {
  .p-family__bottom {
    margin-top: 80px;
  }
}

.p-family__part {
  max-width: 1045.73px;
  margin: 0 auto 122px;
}

@media only screen and (max-width: 767px) {
  .p-family__part {
    margin: 0 auto 100px;
  }
}

@media only screen and (max-width: 516px) {
  .p-family__part {
    margin: 0 auto 85px;
  }
}

.p-family__icon {
  margin: 0 auto;
  position: relative;
}

.p-family__icon.one {
  max-width: 338.2px;
}

@media only screen and (max-width: 516px) {
  .p-family__icon.one span {
    left: 89px;
  }
}

.p-family__icon.two {
  max-width: 257.77px;
}

.p-family__icon.third {
  max-width: 223.63px;
}

.p-family__icon.four {
  max-width: 283.85px;
}

.p-family__icon span {
  position: absolute;
  top: 50%;
  right: 38px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media only screen and (max-width: 516px) {
  .p-family__icon span {
    font-size: 1.5rem;
    top: 50%;
    left: 109px;
    right: unset;
  }
}

.p-family__box {
  display: flex;
  justify-content: space-between;
}

.p-family__image {
  width: 47%;
}

.c-footer .p-up-to-top__container {
  position: fixed;
  width: 100%;
  height: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
}

.c-footer .p-up-to-top__container__is__footer {
  position: absolute;
  top: 0;
}

.c-footer .c-up-to-top {
  position: absolute;
  bottom: 59px;
  right: 33px;
  width: 46.65px;
  height: 46.65px;
  background-color: #3F3B3A;
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  z-index: 998;
  border-radius: 50%;
}

.c-footer .is_footer {
  bottom: -28px;
}

.c-footer .c-up-to-top .c-arrow__button img {
  width: 19.62px;
}

.c-arrow__button {
  display: flex;
  align-items: center;
}

.c-footer {
  position: relative;
}

.c-arrow__button.c-arrow__black {
  display: none;
}

.c-up-to-top:hover .c-arrow__black {
  display: flex;
}

.c-up-to-top:hover {
  background-color: white;
  border: 1px solid #3F3B3A;
}

.c-up-to-top:hover .c-arrow__white {
  display: none;
}

@media only screen and (max-width: 516px) {
  .c-footer .c-up-to-top {
    right: 20px;
  }
  .c-footer .p-up-to-top__container {
    position: absolute;
  }
}

/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Utility .u-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
