/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/

.p {
    &-top {}
    &-top-mv__scroll {}
    &-top1 {}
    &-top1__gray {
        padding: 101px 20px 56px;
        background-color: #F6F6F6;
        @media only screen and (max-width: 767px) {
            padding: 80px 20px 56px;
        }
        @media only screen and (max-width: 516px) {
            padding: 70px 20px 56px;
        }
    }
    &-top1__ueki {
        max-width: 729.063px;
        margin: 0 auto;
        @media only screen and (max-width: 1024px) {
            max-width: 621.063px;
        }
    }
    &-top1__desc {
        margin-top: 24px;
        font-size: 2rem;
        text-align: center;
        line-height: 2.1;
        @media only screen and (max-width: 1024px) {
            font-size: 1.8rem;
        }
        @media only screen and (max-width: 767px) {
            line-height: 1.9;
            font-size: 1.6rem;
        }
    }
    &-top1__white {
        max-width: 1140.16px;
        margin: 97px auto 90px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 20px;
        @media only screen and (max-width: 767px) {
            margin: 80px auto;
        }
    }
    &-top1__title {
        font-size: 2rem;
        position: relative;
        display: inline-block;
        margin: 0 auto;
        @media only screen and (max-width: 516px) {
            font-size: 1.8rem;
        }
        &::after {
            content: "";
            background-color: #005BAC;
            position: absolute;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            bottom: -3px;
            height: 1.745px;
            width: 74.906px;
        }
    }
    &-top1__list {
        margin-top: 59px;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        @media only screen and (max-width: 767px) {
            flex-wrap: wrap;
        }
    }
    &-top1__item {
        width: 21.5%;
        display: flex;
        flex-direction: column;
        @media only screen and (max-width: 1024px) {
            width: 22.5%;
        }
        @media only screen and (max-width: 767px) {
            width: 46%;
            margin-bottom: 40px;
        }
        @media only screen and (max-width: 516px) {
            width: 48%;
            margin-bottom: 30px;
        }
    }
    &-top1__image {}
    &-top1__arrow {}
    &-top1__bottom {
        margin-top: -16px;
        background-color: #F5F7FC;
        padding: 13px 21px;
        flex: 1;
        @media only screen and (max-width: 516px) {
            padding: 13px 14px;
        }
    }
    &-top1__info {
        font-size: 1.7rem;
        text-align: center;
        color: #185497;
        @media only screen and (max-width: 516px) {
            font-size: 1.5rem;
        }
    }
    &-top1__content {
        line-height: 1.7;
        margin-top: 5px;
    }
    &-top1__big {
        margin: 43px 0;
    }
    &-top1__pc {
        @media only screen and (max-width: 767px) {
            display: none;
        }
    }
    &-top1__sp {
        display: none;
        @media only screen and (max-width: 767px) {
            display: block;
        }
    }
    &-top2 {
        background-image: url(../img/index/top2_bg.jpg);
        background-size: cover;
        padding-bottom: 12px;
    }
    &-top2__button {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        margin-top: 74px;
    }
    &-top2__title {
        font-size: 2.4rem;
        text-align: center;
        background-color: #3B7AAF;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        height: 66px;
        color: white;
        letter-spacing: 4px;
        @media only screen and (max-width: 516px) {
            font-size: 2rem;
        }
    }
    &-top2__list {
        .slick-slide {
            margin: 0 13px;
        }
    }
    &-top2__item {}
    &-top2__image {}
    &-top2__info {
        margin-top: 9px;
        text-align: center;
        letter-spacing: 2px;
        @media only screen and (max-width: 516px) {
            letter-spacing: 0;
        }
    }
    &-top2__link {
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        width: 193.745px;
        height: 48px;
        border: 1px solid;
        margin: 54px auto;
        letter-spacing: 3px;
    }
    &-top3 {
        padding: 0 20px;
        max-width: 1079.432px;
        margin: 137px auto 0;
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        @media only screen and (max-width: 767px) {
            margin: 80px auto 0;
        }
    }
    &-top3__title {
        font-size: 1.6rem;
        @media only screen and (max-width: 650px) {
            width: 100%;
            margin-top: 10px;
        }
        @media only screen and (max-width: 516px) {
            font-size: 1.4rem;
        }
    }
    &-top3__list {
        width: 100%;
        margin: 67px 0 10px;
    }
    &-top3__item {
        padding: 10px 0;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        border-bottom: 1px dashed #9F9F9F;
        &:first-child {
            border-top: 1px dashed #9F9F9F;
        }
    }
    &-top3__image {
        width: 81.927px;
        margin-right: 67px;
        @media only screen and (max-width: 1024px) {
            margin-right: 40px;
        }
        @media only screen and (max-width: 767px) {
            margin-right: 20px;
        }
    }
    &-top3__right {
        display: flex;
        @media only screen and (max-width: 650px) {
            flex-wrap: wrap;
        }
    }
    &-top3__date {}
    &-top3__category {
        font-size: 1.213rem;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        min-width: 98.78px;
        height: 21.35px;
        background-color: #9FA0A0;
        padding: 0 20px;
        margin-left: 21px;
        margin-right: 19px;
        color: white;
    }
    &-top4 {}
    &-top4__list {}
    &-top4__link {}
}

.p-top2__meta {
    padding-top: 74px;
    position: relative;
}

.p-top2__container {
    width: 88%;
    height: 100%;
    position: relative;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 2;
    @media only screen and (max-width: 767px) {
        width: 82%;
    }
}

.p-top2__left,
.p-top2__right {
    width: 20.44px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 767px) {
        width: 15px;
    }
}

.p-top2__left {
    left: -31.18px;
}

.p-top2__right {
    right: -31.18px;
}

.p-top4 {
    padding: 0 20px;
    max-width: 1147.739px;
    margin: 103px auto 139px;
    width: 90%;
    .slick-slide {
        margin: 0 6px;
    }
    @media only screen and (max-width: 516px) {
        margin: 80px auto 130px;
    }
}

.p-top4__list .slick-prev,
.p-top4__list .slick-next {
    width: 15.024px;
    height: 27.435px;
}

.p-top4__list .slick-next {
    right: -47px;
}

@media only screen and (max-width: 1024px) {
    .p-top4__list .slick-next {
        right: -25px;
    }
}

@media only screen and (max-width: 516px) {
    .p-top4__list .slick-next {
        right: -20px;
    }
}

.p-top4__list .slick-prev {
    left: -47px;
}

@media only screen and (max-width: 1024px) {
    .p-top4__list .slick-prev {
        left: -25px;
    }
}

@media only screen and (max-width: 516px) {
    .p-top4__list .slick-prev {
        left: -20px;
    }
}

.p-top4__list .slick-prev:before,
.p-top4__list .slick-next:before {
    background-size: cover;
    background-image: url(../img/index/arrow_black_left.svg);
    width: 15.024px;
    height: 27.435px;
    z-index: 9;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}

.p-top4__list .slick-next:before {
    background-image: url(../img/index/arrow_black_right.svg);
}

.c {
    &-slide {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        margin-top: 19px;
        padding-left: 56px;
        @media only screen and (max-width: 1024px) {
            padding-left: 30px;
        }
        @media only screen and (max-width: 767px) {
            padding-left: 20px;
        }
    }
    &-slide__black {
        margin-right: -34px;
        width: 8.82%;
        margin-top: 23px;
        @media only screen and (max-width: 767px) {
            margin-right: 0;
            width: 8.82%;
            margin-top: 5px;
        }
    }
    &-slide__right {
        position: relative;
        margin-bottom: -3.5%;
        width: 76.5%;
        @media only screen and (max-width: 516px) {
            width: 84.5%;
        }
    }
    &-slide__image {}
    &-slide__white {
        width: 2.26%;
        position: absolute;
        right: 3.2%;
        top: 42%;
    }
}

@-webkit-keyframes moveScrollBtn {
    0% {
        top: 0;
    }
    100% {
        top: calc(100% - 7px);
    }
}

@keyframes moveScrollBtn {
    0% {
        top: 0;
    }
    100% {
        top: calc(100% - 7px);
    }
}

.p-top-mv__scroll {
    margin-bottom: auto;
    font-size: 0.9rem;
    letter-spacing: 1px;
    color: #3e63a2;
    font-weight: bold;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-family: "Lato", sans-serif;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    line-height: 1.2;
    @media only screen and (max-width: 516px) {
        display: none;
    }
}

@media only screen and (max-width: 1225px) {
    .p-top-mv__scroll {
        left: -63%;
    }
}

@media only screen and (max-width: 1024px) {
    .p-top-mv__scroll {
        left: -9%;
    }
}

.p-top-mv__scroll span {
    position: relative;
    display: block;
    height: 125px;
    width: 1px;
    background-color: #3e63a2;
    margin-top: 5px;
}

.p-top-mv__scroll span::before {
    position: absolute;
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #3e63a2;
    left: -3px;
    top: 0;
    -webkit-animation: moveScrollBtn .9s ease-in-out infinite;
    animation: moveScrollBtn .9s ease-in-out infinite;
}