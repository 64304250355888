/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/

.p {
    &-company {}
    &-company__main {
        max-width: 1138.93px;
        margin: 134px auto 263px;
        padding: 0 20px;
        @media only screen and (max-width: 767px) {
            margin: 100px auto 170px;
        }
        @media only screen and (max-width: 516px) {
            margin: 80px auto 140px;
        }
    }
    &-company__part {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        margin-bottom: 128px;
        @media only screen and (max-width: 767px) {
            margin-bottom: 90px;
        }
    }
    &-top1__title {}
    &-company__list {
        display: flex;
        margin-top: 72px;
        @media only screen and (max-width: 767px) {
            flex-wrap: wrap;
            flex-direction: column-reverse;
            margin-top: 50px;
        }
    }
    &-company__left {
        font-size: 1.6rem;
        width: 58%;
        margin-right: 38px;
        letter-spacing: 1.7px;
        line-height: 1.98;
        @media only screen and (max-width: 1024px) {
            margin-right: 30px;
        }
        @media only screen and (max-width: 767px) {
            width: 100%;
        }
    }
    &-company__content {}
    &-company__sign {
        text-align: right;
        margin-top: 16px;
        margin-right: 8px;
    }
    &-company__right {
        margin-top: -10px;
        width: 38.5%;
        @media only screen and (max-width: 1024px) {
            margin-top: 0;
        }
        @media only screen and (max-width: 767px) {
            width: 100%;
            max-width: 423px;
            margin-bottom: 30px;
        }
    }
    &-company__table {
        width: 100%;
        margin-top: 83px;
        @media only screen and (max-width: 767px) {
            margin-top: 60px;
        }
    }
    &-company__item {
        display: flex;
        border-bottom: 1px solid #C9CACA;
        &:first-child {
            border-top: 1px solid #C9CACA;
        }
        @media only screen and (max-width: 516px) {
            flex-wrap: wrap;
        }
    }
    &-company__info {
        width: 22.5%;
        position: relative;
        margin: 7px 0px;
        padding-top: 22px;
        border-right: 1px solid #C9CACA;
        @media only screen and (max-width: 516px) {
            width: 100%;
            border-right: none;
        }
    }
    &-company__text {
        width: 70%;
        padding: 29px 43px;
        line-height: 1.8;
        letter-spacing: 1px;
        @media only screen and (max-width: 767px) {
            padding: 29px 0 29px 29px;
        }
        @media only screen and (max-width: 516px) {
            width: 100%;
            border-right: none;
            padding: 20px 0 29px;
        }
        p {
            margin-bottom: 26px;
        }
    }
}

.c {
    &-subpage {
        margin-top: 19px;
    }
    &-subpage__title {
        height: 93.45px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #F6F6F6;
        h2 {
            font-size: 2rem;
        }
    }
}

.p {
    &-service__container {
        margin: 90px auto 89px;
        max-width: 1038.44px;
        padding: 0 20px;
    }
    &-service__top {
        display: flex;
        flex-wrap: wrap;
        margin-left: calc( var(--px1) * -1);
    }
    &-service__link {
        width: calc(100% / 3 - var(--px1));
        margin-left: var(--px1);
        margin-bottom: 32px;
        font-size: 2rem;
        color: #005BAC;
        border: 1px solid;
        height: 92.5px;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        position: relative;
        box-sizing: border-box;
        @media only screen and (max-width: 1024px) {
            font-size: 1.6rem;
        }
        @media only screen and (max-width: 516px) {
            width: calc(100% / 2 - var(--px1));
        }
        @media only screen and (max-width: 375px) {
            font-size: 1.4rem;
        }
        img {
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translateY(-50%);
            width: 16.43px;
            @media only screen and (max-width: 1024px) {
                right: 20px;
            }
            @media only screen and (max-width: 767px) {
                display: none;
            }
        }
    }
    &-service__part {
        padding-top: 122px;
        &:nth-child(2) {
            padding-top: 0;
        }
    }
    &-service__title {
        font-size: 2.4rem;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        color: white;
        background-color: #3B7AAF;
        position: relative;
        height: 66px;
        letter-spacing: 5px;
        @media only screen and (max-width: 516px) {
            font-size: 2rem;
        }
        img {
            position: absolute;
            right: 33px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            width: 46.65px;
            @media only screen and (max-width: 516px) {
                right: 10px;
                width: 37.65px;
            }
        }
    }
    &-service__content {
        max-width: 1094.17px;
        margin: 79px auto 0;
        padding: 0 20px;
        @media only screen and (max-width: 516px) {
            margin: 70px auto 0;
        }
    }
    &-service__info {
        font-size: 2rem;
        letter-spacing: 2px;
    }
    &-service__list {
        display: flex;
        margin-top: 46px;
        letter-spacing: 1.5px;
        line-height: 2;
        justify-content: space-between;
        @media only screen and (max-width: 767px) {
            flex-wrap: wrap;
        }
        @media only screen and (max-width: 516px) {
            margin-top: 30px;
        }
    }
    &-service__left {
        font-size: 1.6rem;
        width: 45%;
        @media only screen and (max-width: 767px) {
            width: 100%;
        }
    }
    &-service__right {
        width: 51.5%;
        margin-top: 8px;
        @media only screen and (max-width: 767px) {
            margin-top: 30px;
            width: 100%;
        }
    }
    &-service__image {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 57px;
        @media only screen and (max-width: 767px) {
            margin-top: 30px;
        }
        @media only screen and (max-width: 516px) {
            margin-top: 20px;
        }
    }
    &-service__item {
        width: 31%;
    }
    &-service__family {
        color: #005BAC;
        font-size: 1.9rem;
        background-color: #F6F6F6;
        width: 269.46px;
        height: 76.88px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        margin: 0 auto 248px;
        border: 1px solid;
        @media only screen and (max-width: 767px) {
            margin: 0 auto 200px;
        }
        @media only screen and (max-width: 516px) {
            width: 249.46px;
            font-size: 1.6rem;
            margin: 0 auto 160px;
        }
        span {
            margin-left: 51px;
            margin-right: 13px;
            letter-spacing: -1px;
        }
        img {
            width: 37.7px;
        }
    }
}

.p-service__big {
    max-width: 1140.16px;
    margin: 56px auto 41px;
    padding: 0 20px;
}

:root {
    --px1: 61px;
    @media only screen and (max-width: 1024px) {
        --px1: 40px;
    }
    @media only screen and (max-width: 767px) {
        --px1: 20px;
    }
}

.p-recruit__content {
    letter-spacing: 2px;
    line-height: 2;
    font-size: 1.6rem;
    text-align: center;
    margin: 203px 0 343px;
    @media only screen and (max-width: 767px) {
        margin: 150px 0 280px;
    }
    @media only screen and (max-width: 516px) {
        margin: 150px 0 230px;
    }
}

.p-privacy__main {
    padding: 0 20px;
}

.p-privacy__content {
    line-height: 1.6;
    max-width: 787.28px;
    margin: 141px auto 259px;
    @media only screen and (max-width: 767px) {
        margin: 100px auto 170px;
    }
    p {
        margin-bottom: 29px;
        span {
            color: #005298;
            margin: 0 3px;
        }
    }
}

.p {
    &-post {}
    &-post__main {
        padding: 0 20px;
        margin-bottom: 259px;
    }
    &-post__title {
        font-size: 1.8rem;
        text-align: center;
        max-width: 1082.4px;
        margin: 104px auto 30px;
        border-bottom: 1px solid;
        padding-bottom: 14px;
        letter-spacing: 4px;
        @media only screen and (max-width: 516px) {
            letter-spacing: 1px;
        }
    }
    &-post__content {
        max-width: 1018.63px;
        margin: 0 auto;
        p {
            line-height: 1.75;
            margin: 15px 0;
            font-size: 1.5rem;
        }
        img {
            height: auto;
            margin: 15px 0;
        }
    }
}

.p {
    &-example__main {
        padding: 0 20px;
    }
    &-example__list {
        flex-wrap: wrap;
        max-width: 965.07px;
        margin: 157px auto 0;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        @media only screen and (max-width: 767px) {
            margin: 120px auto 0;
        }
        @media only screen and (max-width: 516px) {
            margin: 100px auto 0;
        }
    }
    &-example__item {
        margin-bottom: 68px;
        width: 47%;
        @media only screen and (max-width: 516px) {
            margin-bottom: 40px;
        }
    }
    &-example__image {}
    &-example__title {
        text-align: center;
        font-size: 1.8rem;
        margin-top: 13px;
        letter-spacing: 4px;
        @media only screen and (max-width: 767px) {
            font-size: 1.6rem;
            letter-spacing: 1px;
        }
    }
}

.p {
    &-family {}
    &-family__main {
        margin-bottom: 252px;
        @media only screen and (max-width: 767px) {
            margin-bottom: 200px;
        }
        @media only screen and (max-width: 516px) {
            margin-bottom: 160px;
        }
    }
    &-family__big {
        margin-top: 21px;
    }
    &-top1__pc {}
    &-top1__sp {}
    &-family__bg {
        background-image: url(../img/family/bg.jpg);
        background-size: cover;
        border-bottom: 2px solid;
        padding: 87px 7px 0;
        @media only screen and (max-width: 1024px) {
            padding: 87px 20px 0;
        }
        @media only screen and (max-width: 516px) {
            padding: 60px 20px 0;
        }
        &.pc {
            @media only screen and (max-width: 767px) {
                display: none;
            }
        }
        &.sp {
            display: none;
            @media only screen and (max-width: 767px) {
                display: block;
            }
        }
    }
    &-family__top {
        max-width: 1020.52px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }
    &-family__man {
        width: 23.5%;
    }
    &-family__content {
        margin-top: -14px;
        width: 47%;
        @media only screen and (max-width: 767px) {
            max-width: 475px;
            width: 100%;
            margin: 0 auto;
        }
        h3 {
            font-size: 3.955rem;
            border-bottom: 4px dotted;
            @media only screen and (max-width: 1024px) {
                font-size: 2.8rem;
            }
            @media only screen and (max-width: 767px) {
                font-size: 3.955rem;
            }
            @media only screen and (max-width: 516px) {
                font-size: 2.3rem;
            }
        }
        p {
            line-height: 1.55;
            font-size: 2.5rem;
            letter-spacing: 1px;
            margin-top: 18px;
            @media only screen and (max-width: 1024px) {
                font-size: 2.2rem;
            }
            @media only screen and (max-width: 767px) {
                font-size: 2.5rem;
            }
            @media only screen and (max-width: 516px) {
                font-size: 1.8rem;
            }
            span {
                font-size: 2.9rem;
                @media only screen and (max-width: 1024px) {
                    font-size: 2.6rem;
                }
                @media only screen and (max-width: 767px) {
                    font-size: 2.9rem;
                }
                @media only screen and (max-width: 516px) {
                    font-size: 2rem;
                }
            }
        }
    }
    &-family__people {
        width: 25.5%;
        @media only screen and (max-width: 767px) {
            width: 100%;
            margin: 55px auto 70px;
            max-width: 559.22px;
        }
        @media only screen and (max-width: 516px) {
            margin: 30px auto 50px;
        }
    }
    &-family__title {
        max-width: 1073.75px;
        margin: 20px auto;
        z-index: 2;
        position: relative;
    }
    &-family__text {
        font-size: 2.1rem;
        max-width: 815.65px;
        margin: 0 auto;
        line-height: 1.3;
        @media only screen and (max-width: 767px) {
            position: relative;
            margin: 50px auto;
        }
        @media only screen and (max-width: 516px) {
            font-size: 1.8rem;
            margin: 50px auto 30px;
        }
        span {
            z-index: 2;
            position: relative;
            display: block;
            max-width: 545px;
            margin: 0 auto;
        }
    }
    &-family__cloud {
        @media only screen and (max-width: 767px) {
            max-width: 510px;
            position: absolute;
            top: -66%;
            right: 40px;
        }
        @media only screen and (max-width: 516px) {
            right: 16px;
        }
    }
    &-family__house {
        max-width: 1300px;
        margin: 25px auto -2px;
        @media only screen and (max-width: 767px) {
            max-width: 621.82px;
            z-index: 2;
            position: relative;
        }
    }
    &-family__white {
        margin-top: 53px;
        font-size: 1.8rem;
        padding: 0 20px;
        @media only screen and (max-width: 516px) {
            font-size: 1.6rem;
        }
    }
    &-family__detail {
        font-size: 3.955rem;
        text-align: center;
        @media only screen and (max-width: 1024px) {
            font-size: 2.8rem;
        }
        @media only screen and (max-width: 516px) {
            font-size: 2rem;
        }
    }
    &-family__desc {
        max-width: 861.65px;
        margin: 27px auto 23px;
        letter-spacing: 1px;
    }
    &-family__letter {
        letter-spacing: 0.92px;
        margin: 30px 0 34px;
        line-height: 1.55;
    }
    &-family__list {
        max-width: 951.18px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }
    &-family__item {
        color: white;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        font-size: 3.127rem;
        background-color: #005BAC;
        height: 82px;
        width: 30%;
        border-radius: 11px;
        @media only screen and (max-width: 1024px) {
            font-size: 2.8rem;
        }
        @media only screen and (max-width: 767px) {
            font-size: 2.4rem;
        }
        @media only screen and (max-width: 516px) {
            font-size: 1.6rem;
            height: 71px;
        }
    }
    &-family__bottom {
        margin-top: 137px;
        @media only screen and (max-width: 767px) {
            margin-top: 110px;
        }
        @media only screen and (max-width: 516px) {
            margin-top: 80px;
        }
    }
    &-family__part {
        max-width: 1045.73px;
        margin: 0 auto 122px;
        @media only screen and (max-width: 767px) {
            margin: 0 auto 100px;
        }
        @media only screen and (max-width: 516px) {
            margin: 0 auto 85px;
        }
    }
    &-family__icon {
        margin: 0 auto;
        &.one {
            max-width: 338.2px;
            span {
                @media only screen and (max-width: 516px) {
                    left: 89px;
                }
            }
        }
        &.two {
            max-width: 257.77px;
        }
        &.third {
            max-width: 223.63px;
        }
        &.four {
            max-width: 283.85px;
        }
        position: relative;
        span {
            position: absolute;
            top: 50%;
            right: 38px;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            @media only screen and (max-width: 516px) {
                font-size: 1.5rem;
                top: 50%;
                left: 109px;
                right: unset;
            }
        }
    }
    &-family__box {
        display: flex;
        justify-content: space-between;
    }
    &-family__image {
        width: 47%;
    }
}

.c-footer .p-up-to-top__container {
    position: fixed;
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
}

.c-footer .p-up-to-top__container__is__footer {
    position: absolute;
    top: 0;
}

.c-footer .c-up-to-top {
    position: absolute;
    bottom: 59px;
    right: 33px;
    width: 46.65px;
    height: 46.65px;
    background-color: #3F3B3A;
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    z-index: 998;
    border-radius: 50%;
}

.c-footer .is_footer {
    bottom: -28px;
}

.c-footer .c-up-to-top .c-arrow__button img {
    width: 19.62px;
}

.c-arrow__button {
    display: flex;
    align-items: center;
}

.c-footer {
    position: relative;
}

.c-arrow__button.c-arrow__black {
    display: none;
}

.c-up-to-top:hover .c-arrow__black {
    display: flex;
}

.c-up-to-top:hover {
    background-color: white;
    border: 1px solid #3F3B3A;
}

.c-up-to-top:hover .c-arrow__white {
    display: none;
}

@media only screen and (max-width: 516px) {
    .c-footer .c-up-to-top {
        right: 20px;
    }
    .c-footer .p-up-to-top__container {
        position: absolute;
    }
}