/*------------------------------------------------------------
footer
------------------------------------------------------------*/

.c {
    &-footer {
        padding-top: 78px;
        background-color: #F5F7FC;
        padding-bottom: 63.97px;
    }
    &-footer__list {
        display: flex;
        justify-content: space-between;
        max-width: 1081.65px;
        margin: 0 auto;
        padding: 0 20px;
        @media only screen and (max-width: 1024px) {
            flex-wrap: wrap;
            max-width: 850px;
        }
    }
    &-footer__left {
        @media only screen and (max-width: 767px) {
            width: 100%;
        }
    }
    &-footer__logo {
        width: 278.227px;
    }
    &-footer__address {
        font-size: 1.5rem;
        margin-top: 26px;
        p {
            letter-spacing: 1px;
            margin-bottom: 21px;
        }
    }
    &-footer__map {
        width: 100%;
        max-width: 376.8px;
        height: 250.56px;
        @media only screen and (max-width: 1024px) {
            margin: 0 35px;
        }
        @media only screen and (max-width: 767px) {
            margin: 0;
        }
    }
    &-footer__right {
        margin-top: 25px;
        max-width: 277.65px;
        @media only screen and (max-width: 1024px) {
            margin-bottom: 40px;
        }
        h3 {
            font-size: 2.4rem;
            @media only screen and (max-width: 516px) {
                font-size: 2rem;
            }
        }
        p {
            line-height: 1.4;
            font-size: 1.2rem;
        }
        a {
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            font-size: 1.3rem;
            color: white;
            background-color: #005BAC;
            border-radius: 3px;
            width: 102.91px;
            height: 23.68px;
            margin-top: 17px;
        }
    }
    &-footer__black {
        font-size: 1.2rem;
        background-color: #3F3B3A;
        height: 139.7px;
        margin-top: 14px;
    }
    &-footer__button {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        max-width: 1213.726px;
        margin: 0 auto;
        color: white;
        align-items: center;
        height: 100%;
        padding: 0 20px;
        @media only screen and (max-width: 767px) {
            flex-wrap: wrap;
            height: auto;
        }
    }
    &-footer__link {
        display: flex;
        justify-content: space-between;
        max-width: 233.643px;
        width: 100%;
        @media only screen and (max-width: 767px) {
            margin: 43px 0 20px;
        }
    }
    &-footer__copy {
        @media only screen and (max-width: 767px) {
            width: 100%;
        }
    }
    &-footer__bottom {
        background-color: #005BAC;
        display: flex;
        position: relative;
        height: 63.97px;
        border-top: 1px solid white;
        z-index: 999;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        -webkit-transition: 0.4s -webkit-transform ease-in-out;
        transition: 0.4s -webkit-transform ease-in-out;
        transition: 0.4s transform ease-in-out;
        transition: 0.4s transform ease-in-out, 0.4s -webkit-transform ease-in-out;
        &::after {
            position: absolute;
            content: "";
            background-color: white;
            width: 1px;
            height: 53.197px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    &-footer__item {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media only screen and (max-width: 767px) {
            padding: 0 30px;
        }
        @media only screen and (max-width: 516px) {
            padding: 0 15px;
        }
    }
    &-footer__phone {
        max-width: 226.82px;
        width: 100%;
        display: flex;
    }
    &-footer__mail {
        max-width: 166.951px;
        width: 100%;
    }
}

.c-footer__bottom.is-show {
    -webkit-transform: translateY(0);
    transform: translateY(0);
}